import { toFormValidator } from '@vee-validate/zod'
import * as zod from 'zod'

export const validationSchema = toFormValidator(
    zod.object({
        name: zod.string().min(1, { message: 'Required' }),
        mls: zod.string().min(1, { message: 'Required' }),
        sheetId: zod.string().min(1, { message: 'Required' }),
        listingType: zod.string().min(1, { message: 'Required' }),
        provider: zod.string().min(1, { message: 'Required' }),
        propertyTypes: zod.array(zod.string().min(1)).nonempty()
    })
)
